<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="20" class="top_l">
					<el-col :span="4" :xs="24"><el-input v-model="search" clearable placeholder="请输入昵称查询"></el-input></el-col>
					<el-col :span="4" :xs="24"><el-input v-model="usernamenes" clearable placeholder="请输入账号查询"></el-input></el-col>
					<el-col :span="2" :xs="6"><el-button type="primary" @click="searchClick">搜索</el-button></el-col>
					<el-col :span="10" :xs="18">
						<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
							<el-button
								v-if="(item.parentId == twoId && item.permission.includes('add')) || (item.parentId == twoId && item.permission.includes('batch'))"
								:key="index"
								type="primary"
								@click="update(item.permission.slice(5))"
							>
								{{ item.name }}
							</el-button>
						</template>
					</el-col>
				</el-row>
				<el-table
					:data="tableData"
					border
					stripe
					:header-cell-style="{ background: '#eef1f6' }"
					v-loading="serloading"
					@selection-change="handleSelectionChange"
				>
					<el-table-column type="selection" width="50" align="center"></el-table-column>
					<el-table-column type="index" label="序号" align="center" width="70"></el-table-column>
					<el-table-column prop="nickname" label="昵称" />
					<el-table-column prop="username" label="用户账号" />
					<el-table-column prop="mobile" label="手机号" />
					<el-table-column prop="email" label="Email" />
					<el-table-column prop="lastLoginIp" label="最近登录IP" />
					<el-table-column label="操作" width="450" align="center">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="handleSee(scope.$index, scope.row)">查看</el-button>
							<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
								<el-button
									v-if="item.parentId == twoId && !item.permission.includes('batch') && !item.permission.includes('add')"
									:key="index"
									size="small"
									type="primary"
									plain
									@click="update(item.permission.slice(5), scope.row)"
								>
									{{ item.name }}
								</el-button>
							</template>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 新增用户 -->
				<el-dialog v-model="dialogVisibleAdd" title="新增用户" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="80px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="昵称" prop="nickname"><el-input placeholder="请输入昵称" v-model="ruleForm.nickname"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="用户账号" prop="username"><el-input placeholder="请输入账号" v-model="ruleForm.username"></el-input></el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="用户密码" prop="password"><el-input placeholder="请输入密码" v-model="ruleForm.password"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="手机号" prop="mobile"><el-input placeholder="请输入手机号" v-model="ruleForm.mobile"></el-input></el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="email" prop="email"><el-input placeholder="请输入Email" v-model="ruleForm.email"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="用户类型" prop="userType">
									<el-select v-model="ruleForm.userType" placeholder="请选择用户类型">
										<el-option label="超级管理员" value="ROOT"></el-option>
										<el-option label="管理员" value="ADMIN"></el-option>
										<el-option label="普通用户" value="USER"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 用户详情 -->
				<el-dialog title="用户详情" v-model="dialogDetails" :fullscreen="fullscreenshow" width="50%">
					<el-descriptions border :column="2">
						<el-descriptions-item label="ID">{{ details.id }}</el-descriptions-item>
						<el-descriptions-item label="昵称">{{ details.nickname }}</el-descriptions-item>
						<el-descriptions-item label="用户账号">{{ details.username }}</el-descriptions-item>
						<el-descriptions-item label="手机号">{{ details.mobile }}</el-descriptions-item>
						<el-descriptions-item label="email">{{ details.email }}</el-descriptions-item>
						<el-descriptions-item label="最近登录IP">{{ details.lastLoginIp }}</el-descriptions-item>
						<el-descriptions-item label="最近登录时间">{{ details.lastLoginTime }}</el-descriptions-item>
						<el-descriptions-item label="登录次数">{{ details.loginCount }}</el-descriptions-item>
						<el-descriptions-item label="创建时间">{{ details.createTime }}</el-descriptions-item>
						<el-descriptions-item label="更新时间">{{ details.updateTime }}</el-descriptions-item>
						<el-descriptions-item label="用户类型">
							<span v-if="details.userType == 'ROOT'">超级管理员</span>
							<span v-else-if="details.userType == 'ADMIN'">管理员</span>
							<span v-else-if="details.userType == 'USER'">普通用户</span>
						</el-descriptions-item>
					</el-descriptions>
					<template #footer>
						<span class="dialog-footer"><el-button @click="dialogDetails = false">取消</el-button></span>
					</template>
				</el-dialog>
				<!-- 编辑用户 -->
				<el-dialog v-model="dialogVisibleEdit" title="编辑用户" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="Editrules" ref="EditruleFormRef" label-width="80px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="昵称" prop="nickname"><el-input v-model="EditruleForm.nickname"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="用户账号" prop="username"><el-input v-model="EditruleForm.username"></el-input></el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="手机号" prop="mobile"><el-input v-model="EditruleForm.mobile"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="email" prop="email"><el-input v-model="EditruleForm.email"></el-input></el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="用户类型" prop="userType">
									<el-select v-model="EditruleForm.userType" placeholder="请选择用户类型">
										<el-option label="超级管理员" value="ROOT"></el-option>
										<el-option label="管理员" value="ADMIN"></el-option>
										<el-option label="普通用户" value="USER"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitClick('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogVisibleEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改密码 -->
				<el-dialog v-model="dialogpassword" title="修改密码" width="30%" :fullscreen="fullscreenshow">
					<el-form :model="modifyruleForm" :rules="modifyrules" ref="modifyruleFormRef" label-width="100px" class="demo-ruleForm">
						<el-form-item label="密码" prop="pass"><el-input placeholder="请输入密码" v-model="modifyruleForm.pass" autocomplete="off"></el-input></el-form-item>
						<el-form-item label="确认密码" prop="checkPass">
							<el-input placeholder="请再次输入密码" v-model="modifyruleForm.checkPass" autocomplete="off"></el-input>
						</el-form-item>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="modifysubmitForm('modifyruleFormRef')">提交</el-button>
							<el-button @click="resetClick('modifyruleFormRef')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 分配用户角色 -->
				<el-dialog title="分配用户角色" v-model="dialogVisibleDistribution" :fullscreen="fullscreenshow" width="50%">
					<el-checkbox v-for="(item, index) in ssignusData" :key="index" v-model="item.checked" :checked="item.checked" :label="item.id">{{ item.name }}</el-checkbox>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="classdata">确定</el-button>
							<el-button @click="dialogVisibleDistribution = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'users',
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.modifyruleForm.checkPass !== '') {
					// this.$refs.modifyruleFormRef.validateField('checkPass');
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.modifyruleForm.pass) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			dialogVisibleDistribution: false,
			userId: '',
			roleIds: [],
			ssignusData: [],
			dialogpassword: false,
			dialogDetails: false,
			multipleSelection: [],
			serloading: false,
			fullscreenshow: false,
			tableData: [],
			details: [],
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			search: '',
			usernamenes: '',
			totals: null,
			twoId: null,
			// 新增
			dialogVisibleAdd: false,
			ruleForm: {
				nickname: '',
				username: '',
				password: '',
				mobile: '',
				email: '',
				userType: ''
			},
			rules: {
				nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
				username: [{ required: true, message: '请输入用户账号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入用户密码', trigger: 'blur' }],
				mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { min: 11, max: 11, message: '长度 11 个字符', trigger: 'blur' }],
				email: [{ required: true, message: '请输入email', trigger: 'blur' }, { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
				userType: [{ required: true, message: '请输入用户类型', trigger: 'change' }]
			},
			// 编辑
			dialogVisibleEdit: false,
			EditruleForm: {
				nickname: '',
				username: '',
				password: '',
				mobile: '',
				email: '',
				userType: '',
				id: ''
			},
			Editrules: {
				nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
				username: [{ required: true, message: '请输入用户账号', trigger: 'blur' }],
				mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { min: 11, max: 11, message: '长度 11 个字符', trigger: 'blur' }],
				email: [{ required: true, message: '请输入email', trigger: 'blur' }, { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
				userType: [{ required: true, message: '请输入用户类型', trigger: 'change' }]
			},
			// 修改密码
			modifyruleForm: {
				pass: '',
				checkPass: '',
				id: '',
				username: ''
			},
			modifyrules: {
				pass: [{ validator: validatePass, trigger: 'blur' }],
				checkPass: [{ validator: validatePass2, trigger: 'blur' }]
			}
		};
	},
	created() {
		// 二级目录ID
		this.twoId = this.$route.query.Id;
		// 获取用户列表
		this.Cluserlist();
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
	},
	
	methods: {
		// 获取用户列表
		Cluserlist() {
			this.serloading = true;
			this.$HTTP.post(
				'user/list',
				{
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					keywords: this.search,
					username: this.usernamenes
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 模糊查询
		searchClick() {
			this.currentPage = 1;
			this.Cluserlist();
		},
		// 权限button按钮方法
		update(methodsNums, row) {
			//触发这个参数的方法
			this[methodsNums](row);
		},
		// 新增
		add() {
			this.dialogVisibleAdd = true;
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		// 新增提交
		submitClick(name) {
			this.$refs[name].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'user/selectByUserName',
						{
							username: this.ruleForm.username
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.data == true) {
									this.$message.error('账号重复');
								} else {
									this.$HTTP.post(
										'user/add',
										{
											nickname: this.ruleForm.nickname,
											username: this.ruleForm.username,
											password: this.ruleForm.password,
											mobile: this.ruleForm.mobile,
											email: this.ruleForm.email,
											userType: this.ruleForm.userType
										},
										response => {
											if (response.status >= 200 && response.status < 300) {
												if (response.data.code == 0) {
													this.dialogVisibleAdd = false;
													this.$message.success(response.data.msg);
													this.resetClick(name);
													// 获取用户列表
													this.Cluserlist();
												} else {
													this.$message.error(response.data.msg);
												}
											} else {
												console.log(response.message);
											}
										}
									);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 多选
		handleSelectionChange(val) {
			this.multipleSelection = [];
			for (var i = 0; i < val.length; i++) {
				this.multipleSelection.push(val[i].id);
			}
		},
		// 批量删除
		batchDelete() {
			if (this.multipleSelection == '') {
				return this.$message.error('请选择删除内容');
			}
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$HTTP.post(
						'user/remove',
						{
							ids: this.multipleSelection
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取用户列表
									this.Cluserlist();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 查看
		handleSee(index, row) {
			this.dialogDetails = true;
			this.$HTTP.post('user/get/' + row.id + '', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					this.details = response.data.data;
				} else {
					console.log(response.message);
				}
			});
		},
		// 编辑
		edit(row) {
			this.dialogVisibleEdit = true;
			this.EditruleForm.nickname = row.nickname;
			this.EditruleForm.username = row.username;
			this.EditruleForm.mobile = row.mobile;
			this.EditruleForm.email = row.email;
			this.EditruleForm.userType = row.userType;
			this.EditruleForm.id = row.id;
		},
		// 编辑用户
		EditsubmitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'user/edit',
						{
							nickname: this.EditruleForm.nickname,
							username: this.EditruleForm.username,
							mobile: this.EditruleForm.mobile,
							email: this.EditruleForm.email,
							userType: this.EditruleForm.userType,
							id: this.EditruleForm.id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogVisibleEdit = false;
									this.$message.success(response.data.msg);
									// 获取用户列表
									this.Cluserlist();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 删除
		delete(row) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					var id = [];
					id.push(row.id);
					this.$HTTP.post(
						'user/remove',
						{
							ids: id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取用户列表
									this.Cluserlist();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 分配角色
		allotRole(row) {
			this.dialogVisibleDistribution = true;
			this.userId = row.id;
			this.$HTTP.post('roles/rolesWithSelected/' + row.id + '', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					this.ssignusData = response.data.data;
				} else {
					console.log(response.message);
				}
			});
		},
		// 分配用户角色
		classdata() {
			this.roleIds = [];
			for (var i = 0; i < this.ssignusData.length; i++) {
				if (this.ssignusData[i].checked == true) {
					this.roleIds.push(this.ssignusData[i].id);
				}
			}
			if (this.roleIds == '') {
				return this.$message.error('请选择角色');
			}
			this.$HTTP.post(
				'user/saveUserRoles',
				{
					roleIds: this.roleIds.join(','),
					userId: this.userId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							// 获取用户列表
							this.Cluserlist();
							this.dialogVisibleDistribution = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 修改密码
		modify(row) {
			this.dialogpassword = true;
			this.modifyruleForm.username = row.username;
			this.modifyruleForm.id = row.id;
		},
		// 修改用户密码
		modifysubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'user/edit',
						{
							username: this.modifyruleForm.username,
							password: this.modifyruleForm.pass,
							id: this.modifyruleForm.id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogpassword = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			this.Cluserlist();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取用户列表
			this.Cluserlist();
		}
	}
};
</script>

<style scoped lang="scss">

</style>
